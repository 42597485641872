jQuery(document).ready(function($){
	"use strict";
	
	/* -- Slider testimony on mobile ------------------------------------- */
	var slider = new Array();

	slider[0] = new Array();
	slider[0]['option'] = {
		infinite: true,
		slidesToShow: 2,
		prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button"></button>',
		nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button"></button>',
		responsive: [
		{
	      breakpoint: 1024,
	      settings: {
	        slidesToShow: 2
	      }
	    },
	    {
	      breakpoint: 640,
	      settings: {
	        slidesToShow: 1,
	        adaptiveHeight: true
	      }
	    }
	  ]
	};

	slider[1] = new Array();
	slider[1]['option'] = {
		infinite: true,
		slidesToShow: 2,
		prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button"></button>',
		nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button"></button>',
		responsive: [
	    {
	      breakpoint: 640,
	      settings: {
	        slidesToShow: 1,
	        adaptiveHeight: true,
	        arrows: false,
					dots: true
	      }
	    }
	  ]
	};

	slider[2] = new Array();
	slider[2]['option'] = {
		infinite: true,
		slidesToShow: 1,
		prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button"></button>',
		nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button"></button>',
		responsive: [
	    {
	      breakpoint: 640,
	      settings: {
	        slidesToShow: 1,
	        adaptiveHeight: true
	      }
	    }
	  ]
	};

	slider[3] = new Array();
	slider[3]['option'] = {
		infinite: true,
		slidesToShow: 4,
		prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button"></button>',
		nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button"></button>',
		responsive: [
	    {
	      breakpoint: 640,
	      settings: {
	        slidesToShow: 1,
	      }
	    }
	  ]
	};

	slider[4] = new Array();
	slider[4]['option'] = {
		infinite: true,
		slidesToShow: 1,
		prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button"></button>',
		nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button"></button>',
		responsive: [
	    {
	      breakpoint: 640,
	      settings: {
	        slidesToShow: 1,
	        adaptiveHeight: true
	      }
	    }
	  ]
	};


	var sliderLength = slider.length;

	function destroySlider(device) {
		var sliderCreated;

		slider[0]['selector'] = $('.slider-default.slider-on-' + device);
		slider[1]['selector'] = $('.slider-focus.slider-on-' + device);
		slider[2]['selector'] = $('.slider-video.slider-on-' + device);
		slider[3]['selector'] = $('.slider-chaires.slider-on-' + device);
		slider[4]['selector'] = $('.slider-photo.slider-on-' + device);

		if(device === 'mobile') {
			sliderCreated = 'desktop';
		}
		else {
			sliderCreated = 'mobile';
		}

		for(var i = 0; i < sliderLength; i++) {
			if (slider[i]['selector'].length) {
				$(slider[i]['selector']).each(function(index, el) {
					if(!$(el).hasClass('slider-on-' + sliderCreated)) {
						$(el).removeClass('active').slick('unslick');
					}
				});
			}
		}
	}

	function customSlider(device) {
		slider[0]['selector'] = $('.slider-default.slider-on-' + device);
		slider[1]['selector'] = $('.slider-focus.slider-on-' + device);
		slider[2]['selector'] = $('.slider-video.slider-on-' + device);
		slider[3]['selector'] = $('.slider-chaires.slider-on-' + device);
		slider[4]['selector'] = $('.slider-photo.slider-on-' + device);

		for (var i = 0; i < sliderLength; i++) {
			if(slider[i]['selector'].length) {
				$(slider[i]['selector']).each(function(index, el) {
					if(!$(el).hasClass('active')) {
						$(el).addClass('active').slick(slider[i]['option']);
					}
				});
			}
		}
	}

	$.loadSlider = function loadSlider() {
		if($('.slick-slider').length) {
			/* -- Slider --------------------------------------------------------- */
			var windowWidth = $(window).width(), activeSlider;

			if(windowWidth >= 1024) {
				customSlider('desktop');
				activeSlider = 'desktop';
			}
			else {
				customSlider('mobile');
				activeSlider = 'mobile';
			}

			/* -- Functions on resize -------------------------------------------- */
			$(window).resize(function() {
				windowWidth = $(window).width();

				if(windowWidth >= 1024) {
					if(activeSlider === 'mobile') {
						destroySlider('mobile');
						customSlider('desktop');
						activeSlider = 'desktop';
					}
				}
				else {
					if(activeSlider === 'desktop') {
						destroySlider('desktop');
						customSlider('mobile');
						activeSlider = 'mobile';
					}
				}
			});
		}
	}

	$.loadSlider();
});
